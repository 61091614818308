import revive_payload_client_Qhig2X7t3C from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_encoding@0.1.13_eslint@9.12.0_jiti@2.3.3_emmkh2wvm2oijdkjwqa5cjutgm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_cj7IehClHl from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_encoding@0.1.13_eslint@9.12.0_jiti@2.3.3_emmkh2wvm2oijdkjwqa5cjutgm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_3mf3SI5y7L from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_encoding@0.1.13_eslint@9.12.0_jiti@2.3.3_emmkh2wvm2oijdkjwqa5cjutgm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_8a2vaEVL2g from "/opt/build/repo/node_modules/.pnpm/nuxt-site-config@2.2.18_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+node@22.7.5_sass@1.79._xvg722mvpwwc3aksolqb2p3kw4/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import supabase_client_ochTmB7uqX from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+supabase@1.4.0/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import payload_client_3K48So20vH from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_encoding@0.1.13_eslint@9.12.0_jiti@2.3.3_emmkh2wvm2oijdkjwqa5cjutgm/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_MzXvVkD36d from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_encoding@0.1.13_eslint@9.12.0_jiti@2.3.3_emmkh2wvm2oijdkjwqa5cjutgm/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_6YoCWblLD7 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_encoding@0.1.13_eslint@9.12.0_jiti@2.3.3_emmkh2wvm2oijdkjwqa5cjutgm/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_2caY3sccnt from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_encoding@0.1.13_eslint@9.12.0_jiti@2.3.3_emmkh2wvm2oijdkjwqa5cjutgm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_ec5zXYHbU6 from "/opt/build/repo/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.0_typescript@5.6.2_vue@3.5.11_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_ZvUhL7A5U2 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_encoding@0.1.13_eslint@9.12.0_jiti@2.3.3_emmkh2wvm2oijdkjwqa5cjutgm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import preview_client_lbKghO4qWB from "/opt/build/repo/node_modules/.pnpm/@nuxthq+studio@2.1.1_magicast@0.3.5_rollup@4.24.0_webpack-sources@3.2.3/node_modules/@nuxthq/studio/dist/runtime/plugins/preview.client.js";
import plugin_client_xugf1kGpui from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+plausible@1.0.3_magicast@0.3.5_rollup@4.24.0_webpack-sources@3.2.3/node_modules/@nuxtjs/plausible/dist/runtime/plugin.client.js";
import floating_vue_EIcJ7xiw0h from "/opt/build/repo/.nuxt/floating-vue.mjs";
import pwa_icons_plugin_LnrhIJeMG7 from "/opt/build/repo/.nuxt/pwa-icons-plugin.ts";
import pwa_client_8vGg6rM6yi from "/opt/build/repo/node_modules/.pnpm/@vite-pwa+nuxt@0.10.5_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+node@22.7.5_sass@1.79.4__62prbygazqdo2iqjbakhl5z5ra/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import auth_redirect_5EDtur7iPR from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+supabase@1.4.0/node_modules/@nuxtjs/supabase/dist/runtime/plugins/auth-redirect.js";
import slideovers_Kw8TBe26o0 from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui@2.18.6_idb-keyval@6.2.1_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+node@22.7.5_s_ek4tb2ltelr2ha4n2h5gt4c4ua/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_Jfgil3m307 from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui@2.18.6_idb-keyval@6.2.1_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+node@22.7.5_s_ek4tb2ltelr2ha4n2h5gt4c4ua/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_4K878YY40X from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui@2.18.6_idb-keyval@6.2.1_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+node@22.7.5_s_ek4tb2ltelr2ha4n2h5gt4c4ua/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_xyy6tkAUk1 from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.5.1_magicast@0.3.5_rollup@4.24.0_webpack-sources@3.2.3/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_mkiaDXnam9 from "/opt/build/repo/node_modules/.pnpm/@nuxt+icon@1.5.5_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+node@22.7.5_sass@1.79.4_terse_djxobnrygabb4xc6fuv7h3sks4/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import scrollbars_client_heEFgExesp from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui-pro@1.4.3_idb-keyval@6.2.1_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+node@22.7._bjaol44ryt3ss7rietu2kugow4/node_modules/@nuxt/ui-pro/modules/pro/runtime/plugins/scrollbars.client.ts";
import plugin_FShvVjObRv from "/opt/build/repo/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.0_typesc_j5zagxey6ynsxv73mlrfwtha7a/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import presets_7dtzdlJZs5 from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui-pro@1.4.3_idb-keyval@6.2.1_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+node@22.7._bjaol44ryt3ss7rietu2kugow4/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import variables_qNK8v5HKvZ from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui-pro@1.4.3_idb-keyval@6.2.1_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+node@22.7._bjaol44ryt3ss7rietu2kugow4/node_modules/@nuxt/ui-pro/plugins/variables.ts";
import floating_zzW5zaa1LU from "/opt/build/repo/plugins/floating.ts";
import sentry_3AyO8nEfhE from "/opt/build/repo/plugins/sentry.ts";
import variables_M3EiEc4WpY from "/opt/build/repo/plugins/variables.ts";
import plugin_auto_pageviews_client_oNTPhGQHd8 from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+plausible@1.0.3_magicast@0.3.5_rollup@4.24.0_webpack-sources@3.2.3/node_modules/@nuxtjs/plausible/dist/runtime/plugin-auto-pageviews.client.js";
export default [
  revive_payload_client_Qhig2X7t3C,
  unhead_cj7IehClHl,
  router_3mf3SI5y7L,
  _0_siteConfig_8a2vaEVL2g,
  supabase_client_ochTmB7uqX,
  payload_client_3K48So20vH,
  navigation_repaint_client_MzXvVkD36d,
  check_outdated_build_client_6YoCWblLD7,
  chunk_reload_client_2caY3sccnt,
  plugin_vue3_ec5zXYHbU6,
  components_plugin_KR1HBZs4kY,
  prefetch_client_ZvUhL7A5U2,
  preview_client_lbKghO4qWB,
  plugin_client_xugf1kGpui,
  floating_vue_EIcJ7xiw0h,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_8vGg6rM6yi,
  auth_redirect_5EDtur7iPR,
  slideovers_Kw8TBe26o0,
  modals_Jfgil3m307,
  colors_4K878YY40X,
  plugin_client_xyy6tkAUk1,
  plugin_mkiaDXnam9,
  scrollbars_client_heEFgExesp,
  plugin_FShvVjObRv,
  presets_7dtzdlJZs5,
  variables_qNK8v5HKvZ,
  floating_zzW5zaa1LU,
  sentry_3AyO8nEfhE,
  variables_M3EiEc4WpY,
  plugin_auto_pageviews_client_oNTPhGQHd8
]